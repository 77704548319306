<template>
  <v-select
    v-model="innerValue"
    v-bind="$attrs"
    v-on="$listeners"
    :label="$t('hr.travel.leave_request')"
    item-value="id"
    :loading="loading || fetching"
    :items="items"
    color="tertiary"
    :menu-props="{ offsetY: true, overflowY: true }"
  >
    <template #selection="{ item }">
      <v-list-item-avatar size="32" :color="item.leaveType.color" class="mr-2">
        <v-icon size="20">{{ item.leaveType.icon }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.leaveType.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ item.beginLeaveRequestDay.date || formattedDate }} -
          {{ item.endLeaveRequestDay.date || formattedDate }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template #item="{ item }">
      <v-list-item-avatar size="32" :color="item.leaveType.color" class="mr-2">
        <v-icon size="20">{{ item.leaveType.icon }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.leaveType.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ item.beginLeaveRequestDay.date || formattedDate }} -
          {{ item.endLeaveRequestDay.date || formattedDate }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2021-01-05 17:31:57
   * @modify date 2021-01-05 17:31:57
   * @desc LeaveRequestPicker
   */
  import gql from "graphql-tag";
  import { LEAVE_REQUEST_STATUS } from "@/helpers/enums";

  export default {
    name: "LeaveRequestPicker",
    inheritAttrs: false,
    props: {
      value: [String, Object],
      loading: Boolean
    },
    data: vm => ({
      fetching: false,
      items: []
    }),
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        }
      }
    },
    methods: {
      fetchItems() {
        const options = {
          query: gql`
            query filterLeaveRequests($criteria: LeaveRequestFilterCriteria!) {
              filterLeaveRequests(criteria: $criteria) {
                id
                leaveType {
                  color
                  name
                  icon
                }
                beginLeaveRequestDay {
                  date
                }
                endLeaveRequestDay {
                  date
                }
                leaveRequestDays {
                  id
                }
              }
            }
          `,
          fetchPolicy: "no-cache",
          variables: {
            criteria: {
              employees: [this.$store.state.auth.user.id],
              leaveRequestStatuses: [LEAVE_REQUEST_STATUS.APPROVED],
              beginDate: this.$moment().format("YYYY-MM-DD"),
              endDate: this.$moment()
                .add(1, "years")
                .format("YYYY-MM-DD")
            }
          }
        };
        this.fetching = true;
        this.$apollo
          .query(options)
          .then(({ data: { error, filterLeaveRequests }, errors }) => {
            if (!error && !errors) {
              this.items = filterLeaveRequests;
              if (
                this.value &&
                !this.items.some(item => (this.returnObject ? item.id === this.value.id : item.id === this.value))
              ) {
                this.$emit("input", null);
              }
            }
          })
          .catch(e => this.$helpers.showNotification(e.message))
          .finally(() => (this.fetching = false));
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>

<style></style>
